import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel, Modal } from "react-bootstrap";
import Images from "./images";
import Header from "./header";
import Footer from "./footer";
import { useLocation, useNavigate } from "react-router-dom";
import { BLOGDetailsApi } from "./API/cms.api";
import config from "./config";
import { Helmet } from "react-helmet-async";
import { FaArrowAltCircleLeft } from "react-icons/fa";

function Blogdetail(props) {
  console.log("propsprops", props);

  const location = useLocation();
  const [data, setdata] = useState(null);
  const navigate = useNavigate();
  const [loaders, setLoaders] = useState(true);

  useEffect(() => {
    console.log("aeswaerwer", location);
    let pathname = location.pathname;
    // setdata(props.data)
    console.log("location", pathname, pathname.split("/")[2]);
    gettheblogData(pathname.split("/")[2]);
  }, [location]);

  const dateFormater = (createdAt) => {
    const date = new Date(createdAt);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    console.log(formattedDate);
    return formattedDate;
  };

  const gettheblogData = async (url) => {
    if (url) {
      console.log("url_data", url, "https://backend-galfi.maticz.in/v1/");
      const blogdata = await BLOGDetailsApi(url);
      console.log("resp_blogdata", blogdata, blogdata?.data);
      if (blogdata?.status == true) {
        setdata(blogdata.data);
        setLoaders(false)
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>GalFi Blogs - Latest News, Updates, and NFT Releases </title>
        <meta
          name="description"
          content="Stay informed with GalFi blogs. Learn about upcoming NFT drops, game updates, strategy tips, ambassador programs, and thrilling sci-fi announcements."
        />
        <meta name="keywords" content="GalFi Updates and News"></meta>
        <meta
          property="og:title"
          content="GalFi Blogs - Latest News, Updates, and NFT Releases"
        />
        <meta
          property="og:description"
          content="Stay informed with GalFi blogs. Learn about upcoming NFT drops, game updates, strategy tips, ambassador programs, and thrilling sci-fi announcements."
        />
      </Helmet>
      <div className="blog pos">
        <Header />
        <div className="innerpagetop pb-5">
          <Container className="custom_contain">
            <div className="text-start pt-2 pb-4">
              <button className="btn viewmore" onClick={() => navigate(-1)}>
                <FaArrowAltCircleLeft className="bsvg" /> Back
              </button>
            </div>

            {loaders ? (
              <div className="load_h">
                <span class="loaderzz"></span>
              </div>
            ) : (
              <>
                <h1 className="text-white text-center galfi_text lhs fw-600 mt-3 pb-4">
                  {data?.heading}
                </h1>
                <div className="path_cutz text-center mt-4 pb-3">
                  <img
                    src={`${config.CDN}${data?.image}`}
                    className="img-fluid detimg blogdetailimg"
                    alt="galfi_blogs"
                    width="100"
                    height="100"
                  />
                </div>
                <p className="text-white mt-4">
                  {/* {data?.description}  */}
                  <div
                    className="des_para"
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  ></div>
                </p>
                <div className="d-flex align-items-center gap-1 justify-content-end">
                  <p className="mb-0 text-white">
                    {/* John Doe  */}
                    <span className="ms-1">
                      {dateFormater(data?.createdAt)}
                    </span>
                  </p>
                </div>
              </>
            )}
          </Container>
        </div>
        <Container className="custom_contain">
          <Footer />
        </Container>
      </div>
    </>
  );
}

export default Blogdetail;
