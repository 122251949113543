import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Images from "./images";
import { Link } from "react-router-dom";

function Gallerycarousel(props) {
    const { data } = props;

    const options = {
        items: 1,
        responsiveClass: true,
        loop: false,
        margin: 30,
        slideby:1,
    autoplay: false,
        dots: false,
        nav: true,
        navText: [`<img src=${Images.rightarrowimg} width="57px" height="57px" class='leftside' alt="leftarrow" />`, `<img src=${Images.leftarrowimg} width="57px" height="57px" class='leftcaroimg' alt="rightarrow"/>`],
        // rewind: true,
        // slideBy: 4,
      
    };

  return <>
  
  
  
  <OwlCarousel className='owl-theme owlbg' {...options} >
    {console.log('datadatadata',data,props)}
  {data?.promoBuild?.map((e, i) => 
  <div class='item'>
     <div className="row align-items-center">
        <div className="col-lg-6 col-xxl-7">
        <img src={e.image} alt={e.buildingName} className="pathcutz" width={"320px"} height={"100%"}/>
        </div>
        <div className="col-lg-6 col-xxl-5">
            <div className="p-3 p-lg-0">
        <p className="text-white text-left galfi_text nameclr fw-600">{e.buildingName}</p>
        <p className="mb-0 text-white ">{e.description}</p>
        </div>
        </div>
     </div>
    </div>)}

  </OwlCarousel>
  
  
  
  
  
  
  
  
  
  
  </>;
}

export default Gallerycarousel;
