import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Images from "./images";
import Header from "./header";
import Carousel3D from "./carousels";
import Detailmodal from "./Components/detailmodal";
import Marquee from "react-fast-marquee";
import Blogcarousel from "./blogcarousel";
import ReactPlayer from "react-player";
import Footer from "./footer";
import { blogListApi, newsListApi, promoCMS, publishList } from "./API/cms.api";
import config from './config'
import Newssmodal from "./Components/newsmodal";
import { Link } from "react-router-dom";
import Roadmap from "./roadmap";
import Teamprofile from "./teamprofile";
import Gallerycarousel from "./galleycarousel";
import { Helmet } from "react-helmet-async";
import { GetImage } from "./utils/common";


function Landing() {
  const [partnerShip, setpartener] = useState([1, 2, 3, 4]);
  // console.log("logoslogs",partnerShip)
  const [bloglist, setBloglist] = useState([]);
  const [newslist, setNewslist] = useState([]);
  const [detmodal, setDetmodal] = useState(false);
  const [publishlist, setPublishlist] = useState([]);
  const [promoData, setPromoCMSData] = useState([]);
  const [latest, setLatest] = useState(null);
  const [previewblog, setpreviewblog] = useState(null)
  const [previewnews, setpreviewnews] = useState(null)
  const [chakramouse, setChakramouse] = useState(null);
  const [loaders, setLoaders] = useState(true);

  const datetransform = (data) => {
    let date = new Date(data)
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    console.log(formattedDate);
    return formattedDate;
  }
  const fetchdata = async () => {
    const news = await newsListApi()
    const blog = await blogListApi()
    const promocms = await promoCMS()
    const publishon = await publishList()
    console.log("publishon", publishon)
    console.log("🚀 ~ fetchdata ~ promocms:", promocms)

    if (news?.data?.length) {
      setNewslist(news.data)
      setLoaders(false)
      setLatest(news?.data[0])
      setpreviewnews(news?.data[0])

    }
    setpartener(promocms?.data?.parnerts.length ? promocms?.data?.parnerts : [])
    setPromoCMSData(promocms?.data)
    setPublishlist(publishon?.data)

    if (blog?.data?.length) {
      setpreviewblog(blog?.data[0])
      setBloglist(blog?.data)
    }

  }

  useEffect(() => {
    fetchdata()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [newmodal, setNewmodal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // const rotationDegree = 45;
  const [activeTile, setActiveTile] = useState(0);
  const Tile = ({ index, item }) => {
    return (
      <div
        className={`tile`}
        style={{ "--i": index }}
        onClick={() => { onClickChangeArr(index); setSelected(index) }}
      >
        <img onMouseOver={() => setChakramouse(index)} src={chakramouse === index ? item.imghover : selected === index ? item.imghover : item.img} width="100" height="100" className={chakramouse ? "img-fluid chakra__tileImg transition" : "img-fluid chakra__tileImg transition"} alt="introduction galfi" />
        {/* <img src={selected == index ? item.imghover : item.img} className={chakramouse ?"img-fluid chakra__tileImg transition":"img-fluid chakra__tileImg transition" } /> */}

      </div>
    );
  };
  console.log(activeTile, "eeeeeeee");

  const [chakraList] = useState([
    {
      img: Images.galaxy,
      imghover: Images.galaxyhover,
      title: "Developing Planets & Generating Resources",
      desc: "Building structures on Planet and Asteroid NFTs is the core activity of the economic simulation. Buildings cost tokenised resources to build, and different buildings generate different resources daily. They are key to any strategy to maximise resource production and profits for players, providing a reliable income of tokens on a daily basis. For details of the buildings available please see the chapter Buildings [link to chapter] later in this whitepaper. Planets and Asteroids are NFT collections; players will need to own Planets and / or Asteroids to be able to build buildings on them and develop their worlds.",
    },
    {
      img: Images.layer,
      imghover: Images.layerhover,
      title: "Staking",
      desc: "Staking is another reliable method of generating income for players. There are 17 supported NFT collections that can be staked for passive daily tokenised resources. These collections are outlined in the chapter Crew NFTs [link to the chapter]. GalFi utilises soft-staking, this means the game simply reads your wallet address to verify you own NFTs, and then automatically starts generating daily rewards. The game contract does not actually interact with the NFTs in any way; this means that your NFTs are not “locked” into the game or contract, and your NFTs are always safe as the game operates by reading the wallet address only. GalFi will also release its own Crew NFTs and Specialist NFTs. These Crew and Specialist NFTs will be much more useful for missions and resource generation than the other supported collections. Users can also stake their tokens in the GalFi Nexus directly, receiving a share of 1% of all the funds flowing into the GalFi Nexus wallet from all player purchases of ships and buildings in-game. All 15 tokens can also be staked for rewards in the DEX liquidity pools hosted by GalFi for trading all tokenised resources.",
    },
    {
      img: Images.rocket,
      imghover: Images.rockethover,
      title: "Building Ships",
      desc: "All of the 22 ships in GalFi except for the Micro Shuttle will be built by players. Ships can be used to run missions, as well as traded on the GalFi Nexus NFT Marketplace. Later, in the Colonisation phase of development ships will be used to colonise planets in the Contested Space zone, participate in epic ship to ship battles, and to invade other players’ colonies that are situated in Contested Space.",
    },
    {
      img: Images.flag,
      imghover: Images.flaghover,
      title: "Running Missions",
      desc: "GalFi has 4 types of missions: Mining, Exploration, Social, and Combat. Ships can be loaded with crew NFTs (from the 17 supported collections and the GalFi Crew and Specialist NFTs) and sent on missions. Players can choose to run missions for 30 minutes, 1 hour, 12 hours, 1 day, 3 days, 7 days and 28 days. At the end of the mission the player is rewarded with tokenised resources. The resources earned are based on the type of mission, the NFTs used, and on the ship used: the larger the ship the more NFTs can be sent on the mission, and the more resources that can be earned.",
    },
    {
      img: Images.transact,
      imghover: Images.transacthover,
      title: "Trading",
      desc: "As an economic simulation GalFi has many opportunities for trading. All the game’s currencies and resources can be traded against each other on the in-game DEX. The value of all the tokens will be set by players trading activity in the free markets. For a summary of all the 15 different tokenised resources please see the Tokenomics Chapter [link to chapter here]. GALFI token will be traded on established DEXs on both Ethereum and Polygon at launch. Player built ships, as well as Asteroids and Planets, Crew and Specialist NFTs, and other supported NFTs, can also be traded directly to other players on the GalFi Nexus NFT Marketplace. Well developed Planets and Asteroids will command a premium and be very valuable. Clever players will be able to earn from inefficiencies in the markets as well as selling developed Planets, Ships, and other NFTs: this meta game will be a lucrative activity in itself.",
    },
    {
      img: Images.target,
      imghover: Images.targethover,
      title: "Combat",
      desc: "The Colonisation game update will also introduce PvP combat. Players can engage other players’ fleets in ship to ship combat, and also launch invasions of other players planets situated in Contested Space.",
    },
    {
      img: Images.group,
      imghover: Images.grouphover,
      title: "Colonization",
      desc: "When the Colonisation phase of development launches in Q4 2024 players will be able to colonise planets in the Contested Space star map with Colony Ships. They will then be able to build buildings on these colonised planets to earn resources. However Contested Space lives up to its name, and planets situated in Contested Space can also be invaded by other players.",
    },
    {
      img: Images.shelter,
      imghover: Images.shelterhover,
      title: "Exploration",
      desc: "Exploration missions as described above earn players resources. Also when the Colonisation phase of development is completed in Q4 2024, players can send their ships to explore the Contested Space star map. Many wonders and resources await intrepid explorers, although there is also a risk to lose ships.",
    },
  ]);

  const [medialist] = useState([
    { img: Images.media1, link:"https://playtoearn.com/news/explore-the-infinite-wealth-of-galfi-the-play-to-earn-sci-fi-strategy-game-launching-q4-2024" }, 
    { img: Images.media2, link:"https://cryptomode.com/press-releases/play-to-earn-sci-fi-strategy-game-galfi-launches-soon/" }, 
    { img: Images.media3, link:"https://crypto.news/meet-galfi-the-play-to-earn-sci-fi-strategy-game-launching-q4-2024/" }, 
    { img: Images.media4, link:"https://coinmarketcap.com/community/articles/66bef59cccea3331eec41e9e/" },
    { img: Images.media5, link:"https://u.today/explore-the-infinite-wealth-of-galfi-the-play-to-earn-sci-fi-strategy-game-launching-q4-2024" }, 
    { img: Images.media6, link:"https://www.coinspeaker.com/play-to-earn-sci-fi-strategy-game-galfi-launches-soon/" }, 
    { img: Images.media7,  }, 
    { img: Images.media8 },
    { img: Images.media9,  link:"https://news.bitcoin.com/play-to-earn-sci-fi-strategy-game-galfi-launches-soon-offering-strategic-gameplay-and-limitless-earning-potential/"}, 
    { img: Images.media10, link:"https://ambcrypto.com/explore-the-infinite-wealth-of-galfi-the-play-to-earn-sci-fi-strategy-game-launching-q4-2024/" }, 
    { img: Images.media11, link:"https://www.binance.com/en/square/post/12236930592554" }, 
    { img: Images.media12, link:"https://www.newsbtc.com/sponsored/explore-the-infinite-wealth-of-galfi-the-play-to-earn-sci-fi-strategy-game-launching-q4-2024/" },
  ])

  const [builtonlist] = useState([
    { img: Images.builton1 }, { img: Images.builton2 }, { img: Images.builton8 }, { img: Images.builton4 },
    { img: Images.builton5 }, { img: Images.builton6 }, { img: Images.builton7 }, { img: Images.builton3 },
  ])

  const [selected, setSelected] = useState(0);

  // const [animator, setAnimator] = useState(false)
  // const [isReversed, setIsReversed] = useState(false);

  const onClickChangeArr = (i) => {


    setActiveTile(i)
    // const arr = [...chakraList]
    // const sliceArr = arr.slice(i,arr.length);
    // const fullArr = [...sliceArr,...arr.slice(0,i)]
    // console.log("sliceArr",sliceArr,i,arr,fullArr);    
    // setActiveTile(i)

    // setAnimator(true)
    // setIsReversed(false)
    // setTimeout(() => {
    //   setAnimator(false)
    //   setIsReversed(true)
    //   setChakraList(fullArr)
    // }, 300);

  }
  console.log("selected", selected, chakraList[0]);
  return (
    <>
      <Helmet>
         <title>Galactic Finance (GalFi) - Play-to-Earn Sci-Fi Game in Space </title>
        <meta name="description" content="Dive into GalFi, a play-to-earn sci-fi game set in deep space! Explore galaxies, trade NFTs, and build your empire in this immersive Web3 adventure." />
        <meta name="keywords" content="Play-to-Earn Sci-Fi Game"></meta>
        <meta property="og:title" content=" GalFi News - Sci-Fi Game Announcements & Updates  " />
         <meta property="og:description" content="Dive into GalFi, a play-to-earn sci-fi game set in deep space! Explore galaxies, trade NFTs, and build your empire in this immersive Web3 adventure." />
       </Helmet>
      {detmodal && <Detailmodal onDismiss={() => setDetmodal(false)} data={previewblog} />}
      {newmodal && <Newssmodal onDismiss={() => setNewmodal(false)} data={previewnews} />}
      <div className="">
        <Header />

        <div className="play_galfi h_set h_set1 p_top pb-5" id="playgalfi">
          <Container className="custom_contain">
            <Row className="justify-content-between align-items-baseline">
              <Col lg={7} xl={6} xxl={5} className="pos mt-2 mt-lg-5">
                <div
                  // className="d-flex align-items-end mt-xl-5"
                  className="pos mt-xl-5"
                >
                  <img src={Images.circle} className="img-fluid" alt="Forge your path" width="25px" height="290px" />
                  <div className="ps-3">
                    <h1 className="nebula galfi_top text-white pb-2 pb-sm-4 text-uppercase">
                      “Forge your path to riches amongst the stars”
                    </h1>
                    <p className="playbtn cursor text-white mt-1 mt-sm-3 ms-2">
                      {/* Coming Q4 2024 */}
                      Coming Q1 2025
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={5} xl={5} xxl={4} className="galfi_p mt-5 mt-lg-0">
                <p className="text-white lh">
                  GalFi (Galactic Finance) is a tokenised sci fi strategy game
                  and economic simulation on a galactic scale. GalFi is the
                  world’s first science fiction themed “4X” play-to-earn game
                  involving combat, exploration, mining, developing planets,
                  base building, token farming, and trading. All resources such
                  as minerals, and structures such as ships and buildings are
                  mined and created by the players themselves.
                  <br></br>
                  <br></br>
                  GalFi provides intuitive gameplay intertwined with deep
                  strategy, enabling absolute beginners and crypto veterans to
                  earn real crypto exploring, mining, trading, building, and
                  fighting in a vast galaxy of limitless possibilities.
                </p>
              </Col>
            </Row>
          </Container>
        </div>


        <div className="about_galfi galfi_chakraSec pos  py-5">
          <h2 className="px-4 px-xl-3 px-xxl-5 nebula text-white text-center text-uppercase pt-5 pb-4">GalFi - Galactic Finance: Game Introduction and Lore Video Trailer</h2>
          <div className="row pt-3">
            <div className="col-10 col-sm-10 col-md-10 col-lg-7 col-xl-6 col-xxl-5 mx-auto">
              {/* <ReactPlayer width="100%" height="350px" controls={true} playing={true} url='https://www.youtube.com/watch?v=f7xSCWQ6Uck' loop /> */}
              <iframe width="100%" height="350px" title="Galfi"
                src="https://www.youtube.com/embed/f7xSCWQ6Uck">
              </iframe>
            </div>
          </div>
        </div>

        <div className="about_galfi galfi_chakraSec pos h_set py-5" id="aboutgalfi">


          <Container className="custom_contain px-0 px-sm-2 py-0 py-sm-3 h-100">
            <h2 className="nebula text-white text-center pt-4 pb-1 pb-sm-5 pb-sm-2 text-uppercase big_font">
              Introducing Galfi
            </h2>
            <Row className="align-items-center h-100 wraprev">
              <Col xs={12} xl={4}>
                <div className="planets_back px-3 px-md-5 px-lg-5 px-xl-4 px-xxl-4  py-4">
                  <img src={chakraList[selected]?.imghover || chakraList[0].imghover} className="img-fluid mb-2" width="144px" height="150px" alt="galfi_topics" />
                  <p className="text-white nebula  mb-4 text-uppercase galfi_text">
                    {chakraList[selected]?.title || chakraList[0].title}
                  </p>
                  <p className="text-white chakra__descript">
                    {chakraList[selected]?.desc || chakraList[0].desc}
                  </p>
                </div>
              </Col>
              <Col xs={12} xl={8} className="h-100 px-0 px-sm-2">
                <div className="whole__chakra h-100 d-flex justify-content-center align-items-center position-relative">
                  {/* <p className="m-0 chakra__blueTitle"> {selected?.title || chakraList[0].title}</p> */}
                  <div className="chakra__shieldHolder position-relative">
                    <img src={Images.shield} className="chakra__shield" alt="galfi_shield" width="240px" height="240px" />
                    {chakraList.map((item, index) => (
                      <p className={`m-0 alltext ${(chakramouse === index || selected === index) && "active"} point${index}`}>{item.title}
                      </p>
                      // <p className={`m-0 alltext ${(selected == index)  && "active"} point${index}`}>{item.title}
                      // </p> 
                    ))}
                    {console.log(selected, "sadasdasd")}
                    {/* <div className={`border__chakra position-relative chakra${activeTile}`}> */}
                    <div className={`border__chakra position-relative chakra`}>
                      {chakraList.map((item, index) => (
                        <Tile key={index} index={index} item={item} />
                      ))}
                      <img src={Images.cloudChakra} className="cloud__chakra" width="95%" height="95%" alt="galfi_chakra" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="lore h_set pos" id="lore">
          <Container className="custom_contain">
            <Row className="align-items-center">
              <Col lg={6} xl={6} xxl={7} className="text-center text-lg-end">
                <img src={Images.astronaut} className="img-fluid astronaut" width="670px" height="100" alt="astronaut" />
              </Col>
              <Col lg={6} xl={6} xxl={5} className="py-5 py-lg-4 py-xl-5 pt-xl-0">
                <div className="ms-xxl-4">
                  <h2 className="nebula text-white text-uppercase big_font">Lore</h2>
                  <p className="text-white mt-3 lh">
                    In the far future humanity had inexorably spread across the
                    cosmos. This was a time of utopia and peace, trillions lived
                    in paradise on a million worlds . There was no war: everyone
                    lived for a thousand Earth years and pursued their highest
                    calling. Beautiful works of art were created, indeed whole
                    worlds were created as art, and humans were on the verge of
                    discovering the secrets of existence itself. Sentient robots
                    and uplifted apes, wolves, dinosaurs and monkeys did
                    humanity’s bidding.
                    <br></br> <br></br>
                    Then came the great collapse. No one was sure how it started,
                    but everyone saw how it ended. All of humanity’s advanced tech
                    became useless in an instant. Trillions perished from famine,
                    disease, and the wars that erupted over the remaining
                    rudimentary resources of each world.
                    <br></br> <br></br>A secretive group known as The Guardians
                    had been protecting highly advanced AI tech for thousands of
                    years. Many gave their lives for their sacred mission. Like a
                    phoenix rising from the ashes, to try and restore order in the
                    galaxy The Guardians created the GalFi Nexus: a
                    hyperintelligent AI to oversee all technology, all trade, and
                    all resource distribution in the galaxy. Under the ever
                    watchful and omniscient GalFi Nexus AI, humanity and their
                    uplifted ape brethren could begin again on their quest to
                    regain their former glory. Will you help them?
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <div className="gallery pos py-5" id="gallery">
          <h2 className="nebula text-white text-center text-uppercase pt-5 pb-4 big_font">
            Gallery
          </h2>
          <div lassName="h_set">
            <Carousel3D data={promoData} />
            {console.log('shfjhsgfgsjpromoData',promoData)}
          </div>
       
        </div> */}

           {/* <h3 className="px-4 px-xl-3 px-xxl-5 nebula text-white text-center text-uppercase pt-5 pb-4">GalFi - Galactic Finance: Game Introduction and Lore Video Trailer</h3>
          <div className="row pt-3">
            <div className="col-10 col-sm-10 col-md-10 col-lg-7 col-xl-6 col-xxl-5 mx-auto">
              <ReactPlayer width="100%" height="350px" controls={true} playing={true} url='https://www.youtube.com/watch?v=f7xSCWQ6Uck' loop />
            </div>
          </div> */}


        <div className="gallery pos py-5" id="gallery">
          <Container className="custom_contain">
        <h2 className="nebula text-white text-center text-uppercase pt-4 pb-3 big_font">
            Gallery
          </h2>
          { 
            promoData?.promoBuild?.length > 0 
            ?
         <Gallerycarousel data={promoData}/>
            :
            <></>
          }
          </Container>
        </div>

        <div className="blog  pt-5 pb-3" id="blog">
          <Container className="custom_contain py-3">
            <h2 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              Blog
            </h2>
            <Row className="justify-content-center">
              <Col lg={7} xl={6} xxl={7}>
                {/* <h2 className="text-white nebula mb-4 text-uppercase">Latest Blog</h2> */}
                {previewblog ?
                  <Link to={`/blog/${previewblog.url}`} className="pos_stick">
                    <div
                      className="blogcardback pos cursor px-3 py-3"
                    // onClick={() => setDetmodal(true)}
                    >
                      <div className="pathcut">
                        <img src={previewblog?.image} alt='galfi_blog' width="100px" height="100px" className="img-fluid blogimg" />
                      </div>
                      <p className="text-white blogtitle blog_subtxt fw-600 ps-3 pe-5">
                        {previewblog?.heading}
                      </p>
                      <div className="dateimg d-flex align-items-center gap-1 px-3">

                        <p className="mb-0 text-white">
                          {/* <span className="ms-1">{datetransform(previewblog?.createdAt)}</span> */}

                        </p>
                      </div>
                    </div>
                  </Link> :
                  <><p className="no_data nebula text-uppercase">No Blogs Yet</p></>}
              </Col>
              <Col
                lg={5}
                xl={6}
                xxl={5}
                className="blog_heights py-2 mt-5 mt-lg-0"
              >
                {/* <h2 className="text-white nebula mb-4 text-uppercase ms-2">Blogs</h2> */}
                <Row>
                  {bloglist.length > 0 ?
                    bloglist.slice(0, 3).map((e, i) => (
                      <Col sm={10} md={6} lg={12} className="mx-auto">
                        <Link to={`/blog/${e.url}`}>
                          <div className="simplecard pos px-2 py-2 mb-3"
                          //  onClick={() => setpreviewblog(e)}
                          >
                            <div className="pathcuts">
                              {e.image && <img
                                src={e.image}
                                className="img-fluid blogimg1"
                                alt="galfi"
                                width="540px"
                                height="100px"
                              />}
                            </div>
                            <p className="text-white blogtitle blog_subtxt fw-600 ps-3 pe-5">
                              {e?.heading?.slice(0, 48) + '...'}
                            </p>
                            <div className="dateimg d-flex align-items-center gap-1 px-3">

                              <p className="mb-0 text-white">
                                {console.log('xxxxx', e)}
                                <span className="ms-1">{datetransform(e?.createdAt)}</span>
                              </p>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    )) :
                    <><p className="no_data b_data nebula text-uppercase">No Blogs Yet</p></>}
                </Row>
              </Col>
            </Row>


            
            {/* <div className="text-center pt-4">
              <Link to="/blogs"><button className="btn viewmore">View More</button></Link>
            </div> */}
            {/* <div className="pt-4 pb-4">
              
              <Blogcarousel />
            </div>
            <div className="pt-5 pb-5">
              <Marquee speed={50} direction="left"><h1
                {partnerShip.map((e, i) => (
                  <div className="marquee_whole">
                    <img
                      src={e.image} alt={e.companyName}
                      className="img-fluid partnerimages"
                    />

                  </div>
                ))}
              </Marquee>
            </div> */}
          </Container>
        </div>

        <div className="blog pos pt-3 pb-5" id="blogs">
          <Container className="custom_contain py-3">
            <div className="pb-4">
              <Blogcarousel />
            </div>
            <div className="text-center pt-3">
              <Link to="/blogs"><button className="btn viewmore">View More</button></Link>
            </div>
            <div className="pt-5 pb-4">
            <h2 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              Partners
            </h2>          
            {partnerShip && partnerShip?.length > 0 ?
              <Marquee speed={50} direction="left" className="mt-2">
                {partnerShip.map((e, i) => (
                  <div className="marquee_whole">
                    <Link to={e.navLink} target="_blank">
                    <img
                      src={e.image} alt={e.companyName}
                      className="img-fluid partnerimages"
                    />
                    </Link>
                  </div>
                ))}
              </Marquee>
              :
              <p className="no_data p_data nebula text-uppercase">No Data</p>}
            </div>
          </Container>
        </div>

        <div className="media bot_color pos pt-5 pb-5" id="media">
          <Container className="custom_contain pt-3">
            <h2 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              {/* Media */}
              Published on
            </h2>
            {/* <Row className="mt-2 mb-5">
              {medialist.map((e, i) =>
                <Col xs={6} sm={4} md={4} lg={4} xl={3} xxl={3} className="mt-4 text-center">
                  <Link to={e.link} target="_blank">
                  <div>
                    <img src={e.img} alt="Galfi_medialogo" className="img-fluid medialogo" width="260px" height="100px" />
                  </div>
                  </Link>
                </Col>)}
            </Row> */}

            <Row className="mt-2 mb-5">   
              {console.log("dfdf",publishlist)}       
             {publishlist?.map((e, i) => (
                 <Col xs={6} sm={4} md={4} lg={4} xl={3} xxl={3} className="mt-4 text-center">
                 <Link to={e.navLink} target="_blank">
                  <div>
                    <img src={GetImage(e.image)} alt="Galfi_medialogo" className="img-fluid medialogo" width="260px" height="100px" />
                  </div>
                  </Link>
                 </Col>))}
            </Row>


                {/* <h2 className="text-white nebula mb-4 text-uppercase">Latest</h2> */}
                         {/* Media */}


            {/* <Row className="justify-content-between align-items-center mb-4">
              <Col lg={7} xl={8} xxl={8}>

                <h2 className="text-white nebula mb-4 text-uppercase">
         
                  Published on
                  </h2>
                <div className="h_under pos">
                  {console.log("previewnews?.videoxxx", latest?.video)}

                  {latest ?
                    <>
                      {latest?.video === 'true' ? <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="500px"
                        url={`${config.CDN}${latest?.video}`}
                        controls={true}
                      /> : <img src={latest?.image} className="img-fluid newslatest_img" alt="galfi_imglist" width="100" height="100" />}
                      <p className="text-white galfi_text mt-4 mb-4">
                        {latest?.heading}
                      </p>
                      <p className="text-white">
                        <div className="des_para" dangerouslySetInnerHTML={{ __html: latest?.description }}></div>
                      </p>
                    </> :
                    <><p className="no_data b_data nebula text-uppercase">No News Yet</p></>}

                </div>
              </Col>
              <Col lg={5} xl={4} xxl={3} className="h_under mt-5 mt-lg-0">
                <h2 className="text-white nebula mb-4 text-uppercase">News</h2>
                <Row>
                  {newslist.length > 0 ?
                    <>
                      {newslist && newslist.length > 0 && newslist.slice(0, 4).map((e, i) => (
                        <Col sm={6} md={6} lg={12}>
                          <Link to={`/news/${e.url}`}>
                            <div
                              className="news mb-3 pos cursor"
                              onClick={() => {
                                toggleVisibility()
                                setpreviewnews(e)
                                // setNewmodal(true)
                              }}
                            // onClick={toggleVisibility}
                            // onClick={()=>}
                            >
                              {console.log('bloglistx', e)}
                              {e?.image && <img
                                src={e.image}
                                className="img-fluid newsimg"
                                alt="galfi_news"
                                width="100"
                                height="100"
                              />}
                              <p className="text-white new_text mx-1">
                                {e?.heading?.slice(0, 48) + "..."}
                              </p>
                            </div>
                          </Link>
                        </Col>
                      ))}
                    </> :
                    <><p className="no_data b_data nebula text-uppercase">No News Yet</p></>}
                </Row>
              </Col>
            </Row> */}

     {/* <h2 className="text-white nebula mb-4 text-uppercase">
         
         Published on
         </h2>

         {loaders ? 
              <div className="load_h">
                <span class="loaderzz"></span>
              </div>
              :
            <Row>
            <Col xl={8} xxl={9} className="mb-4">
            {latest ?
            <div className="newscards">
              <div className="d-flex  gap-3">
              <div>
                   {latest?.video === 'true' ? <ReactPlayer
                        className="react-player"
                        width="100%"
                        height="500px"
                        url={`${config.CDN}${latest?.video}`}
                        controls={true}
                      /> : <img src={latest?.image} className="img-fluid w-100 latestnews" alt="galfi_imglist" width="100" height="100" />}
           </div>
            <div>
            <p className="text-white fw-700 htag mt-4 mb-4">
                        {latest?.heading}
                      </p>
                      <p className='text-white newstime mb-3'>
                      <span className='ms-0'>{datetransform(latest?.createdAt)}</span></p>
                      <p className="text-white newstime">
                        <div className="des_para" dangerouslySetInnerHTML={{ __html: latest?.description?.slice(0, 150) + "..." }}></div>
                      </p>
            </div>
            </div>
         
            </div>
            :
            <><p className="no_data b_data nebula text-uppercase">No News Yet</p></>
            }

        
            </Col>

           
            {newslist && newslist.length > 0 && newslist.slice(0, 4).map((e, i) => (
              <Col md={6} lg={6} xl={4} xxl={3} className="newslist">
                 <Link to={`/news/${e.url}`}>
                <div className="newscard mb-4">
                {e?.image && <img
                                src={e.image}
                                className="img-fluid w-100"
                                alt="galfi_news"
                                width="100"
                                height="100"
                              />}
                              <div className="p-2">
                              <p className="text-white fw-700 htag"> {e?.heading?.slice(0, 48) + "..."}</p>
                              <p className='text-white newstime mb-3'>
                              <span className='ms-0'>{datetransform(e?.createdAt)}</span></p>
                 <p className="text-white mt-3 newstime cardheight">
                            <div className="des_para newstime" dangerouslySetInnerHTML={{ __html: e?.description?.slice(0, 255) + "..." }}></div>
                        </p>
                              </div>
             
                </div>
                </Link>
              </Col>
                ))}
                
            </Row>
}


            <div className="text-center pt-2">
              <Link to="/news"><button className="btn viewmore">View More</button></Link>
            </div> */}


          </Container>
        </div>

        <div className="roadmapsection pos pt-4 pb-4" id="roadmaps">
          <Container className="custom_contain pt-2">
            <Roadmap />
            {/* <Footer /> */}
          </Container>
        </div>


        <div className="roadmapsection builtons pos pt-4" id="team">
          <Container className="custom_contain pt-2">
            <Teamprofile />
            {/* <Footer /> */}
          </Container>
        </div>


        <div className="roadmapsection builtons pos pt-4" id="builton">
          <Container className="custom_contain pt-2">
            <h2 className="nebula text-white text-center pt-2 pb-1 pb-sm-3 text-uppercase big_font">
              Built On
            </h2>
            <Row className="mt-2 mb-5">
              {builtonlist.map((e, i) =>
                <Col xs={6} sm={4} md={4} lg={4} xl={3} xxl={3} className="mt-4 text-center">
                  <div>
                    <img src={e.img} alt="medialogo" className="img-fluid medialogo" width="260px" height="90px" />
                  </div>
                </Col>)}
            </Row>
            <Footer />
          </Container>
        </div>

      </div>
    </>
  );
}

export default Landing;
