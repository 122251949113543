import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel, Modal } from 'react-bootstrap';
import Images from "./images";
import Header from "./header";
import Footer from "./footer";
import { newsDetailsApi } from "./API/cms.api";
import config from './config';
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaArrowAltCircleLeft } from "react-icons/fa";

function Newsdetail(props) {

    const location = useLocation();
    const [data, setdata] = useState(null);
    const [loaders, setLoaders] = useState(true);
    const navigate = useNavigate();
    // useEffect(() => {
    //     console.log('dosagduyghxhx')
    //     setdata(props.data)
    // }, [props.data]);


    useEffect(() => {
        console.log("aeswaerwer", location);
        let pathname = location.pathname
        // setdata(props.data)
        console.log("location", pathname, pathname.split("/")[2])
        getthenewsData(pathname.split("/")[2])
    }, [location]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    
    const dateFormater = (createdAt) => {
        const date = new Date(createdAt);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        console.log(formattedDate);
        return formattedDate
    }

    const getthenewsData = async (url) => {
        if (url) {
            console.log("url_data", url, "https://backend-galfi.maticz.in/v1/")
            const newsdata = await newsDetailsApi(url);
            console.log("resp_blogdata", newsdata, newsdata?.data);
            if (newsdata?.status == true) {
                setdata(newsdata.data)
                setLoaders(false)
            }
        }
    }

    return (

        <>
         <Helmet>
         <title>GalFi News - Sci-Fi Game Announcements & Updates</title>
        <meta name="description" content="Find the latest GalFi news. Learn about press releases, NFT developments, community updates, and all things related to this play-to-earn sci-fi strategy game." />
        <meta name="keywords" content="GalFi News and Updates"></meta>
        <meta property="og:title" content=" GalFi News - Sci-Fi Game Announcements & Updates  " />
         <meta property="og:description" content="Find the latest GalFi news. Learn about press releases, NFT developments, community updates, and all things related to this play-to-earn sci-fi strategy game." />
       </Helmet>
            <div className="blog pos">
                <Header />
                <div className="innerpagetop pb-5">
                    <Container className="custom_contain">
                    <div className="text-start pt-2 pb-4">
              <button className="btn viewmore" onClick={()=>navigate(-1)}><FaArrowAltCircleLeft className="bsvg"/> Back</button>
            </div>
                 
            {loaders ? 
              <div className="load_h">
                <span class="loaderzz"></span>
              </div>
             :
                 <>
                 
                        <h1 className="text-white galfi_text lhs fw-600">{data?.heading}</h1>
                        {console.log('dataxvideo', data?.video)}
                        {data?.image && <div className="path_cutz text-center mt-4">
                            <img src={`${config.CDN}${data?.image}`} className="img-fluid detimg newslatest_img" width="100" height="100" />
                        </div>}

                        {data?.video &&
                            <div className="mt-3 mx-xxl-4">
                                <ReactPlayer
                                    className="react-player"
                                    width="100%"
                                    height="500px"
                                    url={`${config.CDN}${data?.video}`}
                                    controls={true}
                                />
                                 </div>
                        }

                        {/* <div className="mt-3 mx-xxl-4">
                            <ReactPlayer
                                className="react-player"
                                width="100%"
                                height="500px"
                                url={"https://www.youtube.com/watch?v=l6hjm7kFcPo"}
                                controls={true}
                            /> </div> */}


                        <p className="text-white mt-4">
                            <div className="des_para" dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                        </p>

                        <div className="d-flex align-items-center gap-1 justify-content-end">
                            <p className="mb-0 text-white">
                                {/* John Doe  */}
                                <span className="ms-1">{dateFormater(data?.createdAt)}</span></p>
                        </div>
                        </>}



                    </Container>
                </div>
                <Container className="custom_contain">
                    <Footer />
                </Container>
            </div>
        </>
    )
}

export default Newsdetail;
