import config from '../config'

export const GetImage = (path)=>{
    if(!path) return ""
    if(path?.includes("http")){
    return path
    }
    if(path[0] === '/'){
        return `${config.CDN+"/"}${path}` 
    }else{
        return `${config.CDN + path}` 
    }
}