import config from '../config'
import axios  from "axios"

const HandelAxiosError = (err) => {
    if(err?.response?.status >= 400 && err?.response?.status < 500){
        if(err.response?.status == 422){
         localStorage.clear();  
         return err.response.data     
        }
         return { status : false , message : err.response.data.error}   
     }
     return { status : false , message : 'please try later '}
}

export const sociallistApi = async(data) =>{
    try{

        let resp = await axios({
            'method':'get',
            'url':`${config.BACK_V1}/cms/sociallist`, 
        
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
            },
    })
    return resp.data;
    }catch(err){
    
        return HandelAxiosError(err)
    }
}

export const newsListApi = async() =>{
    try{

        let resp = await axios({
            'method':'get',
            'url':`${config.BACK_V1}/promo/newslist`, 
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
            },
    })
    console.log("jkjk",resp);
    
    return resp.data;
    }catch(err){
    
        return HandelAxiosError(err)
    }
}

export const blogListApi = async() =>{
    try{

        let resp = await axios({
            'method':'get',
            'url':`${config.BACK_V1}/promo/bloglists`, 
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
            },
    })
    return resp.data;
    }catch(err){
    
        return HandelAxiosError(err)
    }
}





export const partnerListApi = async() =>{
    try{

        let resp = await axios({
            'method':'get',
            'url':`${config.BACK_V1}/promo/partnerlist`, 
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
            },
    })
    return resp.data;
    }catch(err){
    
        return HandelAxiosError(err)
    }
}

export const promoCMS = async() =>{
    try{

        let resp = await axios({
            'method':'get',
            'url':`${config.BACK_V1}/promo/promocms`, 
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
            },
    })
    return resp.data;
    }catch(err){
    
        return HandelAxiosError(err)
    }
}


export const publishList = async() =>{
    try{

        let resp = await axios({
            'method':'get',
            'url':`${config.BACK_V1}/promo/publish`, 
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": localStorage.getItem("token")
            },
            
    })
    console.log("lll",resp)
    return resp.data;
    }catch(err){
        console.log("err",err)
        return HandelAxiosError(err)
    }
}

export const BLOGDetailsApi = async (url) => {
    try {
        let resp = await axios({
            'method': 'get',
            'url': `${config.BACK_V1}/promo/blog/${url}`,
            "headers": {
                'Content-Type': 'application/json',
                // "Authorization": localStorage.getItem("token")
            },
        });
        return resp.data;
    } catch (err) {

        return HandelAxiosError(err)
    }
}
export const newsDetailsApi = async(url) =>{
    try{

        let resp = await axios({
            'method':'get',
            'url': `${config.BACK_V1}/promo/news/${url}`,
            "headers":{
                'Content-Type': 'application/json',
                // "Authorization": localStorage.getItem("token")
            },
    })
    return resp.data;
    }catch(err){
    
        return HandelAxiosError(err)
    }
}